<template>
    <section id="legal" class="bg_lighgrey">
        <div class="container">
            <div class="row">
                <div v-if="false" class="col-12 aos-init" data-aos="fade-up" data-aos-duration="1000">
                    <h3 class="title_xl title_lightblue">
                        ПРАВИЛА И УСЛОВИ КОРИШЋЕЊА
                    </h3>
                    <p class="text_l mt-3">

                        Поштовани,
                        <br><br>
                        Пред вама се налазе Правила и услови коришћења којимa се регулише коришћење интернет презентације и платформе Serbian Researchers (у даљем тексту: <b>Интернет презентација, Интернет платформа</b> или <b>Платформа</b>) (постојеће на интернет адреси: <a href="https://serbianresearchers.com/" class="title_blue">https://www.serbianresearchers.com/</a> ). Молимо вас да пажљиво прочитате ова Правила и услове коришћења (у даљем тексту: <b>Правила</b>) имајући у виду да се она примењују на вас, односно на ваше коришћење Платформе.
                        <br><br>
                        Поред Правила, и заједно са њима, примењује се и <a href="/privacy-policy" class="title_blue">Политика приватности</a> којом се уређује  прикупљање, обрада, коришћење и заштита личних података, у складу са Законом о заштити података о личности ("Сл. гласник РС", бр. 87/2018).
                        <br><br>
                        Свака посета Платформи или регистрацијом значи да сте упознати, односно прочитали Правила и <a href="/privacy-policy" class="title_blue">Политика приватности</a>, разумели предмет њиховог уређивања и да се слажете са њима у целости. Уколико, пак, они за вас нису прихватљиви, молимо вас да не користите Платформу.

                    </p>

                    <h4 class="title_s title_lightblue mt-4">
                        I	ОСНОВНЕ ОДРЕДБЕ
                    </h4>

                    <p class="text_l mt-3">
                        Организација Serbian Researchers има за циљ умрежавање српских истраживача широм света путем креирања платформе за дељење знања и искуства између појединаца који се баве научно-истраживачким радом у Србији и иностранству. Платформа омогућава повезивање истраживача из различитих респективних сфера и области и доприноси размени идеја и сарадњи између универзитета и компанија. Стога, она је, пре свега, намењена физичким лицима која своје професионалне афинитете реализују радом или сарадњом у академским институцијама, научно-истраживачким институтима и развојно-истраживачким центрима унутар компанија. Такође, Платформи може приступити и свако заинтересовано лице у сврху информисања о темама, радионицама и другим садржајима доступним на њој.
                        <br><br>
                        Интернет презентацијом и платформом администрира и управља Удружење СРПСКИ ИСТРАЖИВАЧИ, које поседује, односно које је носилац одговарајућих права и овлашћења на/у погледу Платформе.
                        <br><br>
                        Приступ Платформи или регистрација на њој заснива се на добровољној основи, те уколико лице предузме наведене радње потврђује да је упознато са Правилима и <a href="/privacy-policy" class="title_blue">Политика приватности</a>, разумело предмет њиховог уређивања и сагласно са њиховом применом.


                    </p>

                    <h4 class="title_s title_lightblue mt-4">
                        II	УГОВОРНЕ СТРАНЕ
                    </h4>

                    <p class="text_l mt-3">
                        Уговорна страна која администрира и управља Платформом је Удружење СРПСКИ ИСТРАЖИВАЧИ, Господар Јованова 43, 11000 Београд, Србија, МБ: 28321406, ПИБ: 112436772 (у даљем тексту: <b>Удружење</b> или <b>Serbian Researchers</b>).
                        <br><br>
                        Уговорна страна која приступа Платформи у сврху упознавања са њом и информисања о темама, радионицама, те уопштено о садржају доступним на њој је Посетилац.
                        <br><br>
                        Уговорна страна која изврши регистрацију, те користи Платформу у сврхе предвиђене овим Правилима (повезивање са другим истраживачима, дељење знања и искуства и сл.) је Регистровани корисник.
                        <br><br>
                        Чланство у Организацији не подразумева нужно и чланство у Удружењу, већ се односи на регистрацију истраживача за добијање статуса Регистрованог корисника Платформе и припадност заједници истраживача коју Организација окупља на Платформи.
                        <br><br>
                        Заједнички именилац за Посетиоца и Регистрованог корисника је Корисник.

                    </p>

                    <h4 class="title_s title_lightblue mt-4">
                        III	НАЧИН КОРИШЋЕЊА ПЛАТФОРМЕ
                    </h4>

                    <p class="text_l mt-3">


                        Платформу могу користити Корисници (Регистровани корисници и Посетиоци) у сврхе и намене наведене у делу I – Основне одредбе, односно у складу са другим одговарајућим одредбама из ових Правила, а сходно природи садржаја који су доступни овим категоријама Корисника.
                        <br><br>
                        А) Регистровани корисник
                        <br><br>
                        За регистрацију је, претходно, потребно да корисник кумулативно испуни два услова:
                        <br><br>
                        -повезаност са одговарајућом институцијом, односно компанијом,
                        <br>
                        -допринос респективној научној области.
                        <br><br>
                        Повезаност са одговарајућом институцијом, односно компанијом корисник остварује радом/запослењем у (алтернативно):
                        <br><br>
                        -акредитованој академској институцији,
                        <br>
                        -научно-истраживачким центрима и институтима,
                        <br>
                        -развојно-истраживачким центрима унутар компанија.
                        <br><br>
                        Допринос респективној научној области корисник остварује (алтернативно):
                        <br><br>
                        -објављивањем најмање једног научног рада, било где у свету, независно да ли је корисник једини аутор или коаутор тог рада,
                        <br>
                        -ауторством проналаска заштићеног патентом (извршена регистрација и призната патентна заштита), независно да ли је корисник продао или лиценцира патент другом лицу,
                         <br>
                        -похађањем докторских студија на акредитованој академској институцији (у Србији или у иностранству).
                        <br><br>
                        Поред испуњења кумулативно постављених услова потребно је да је корисник:
                         <br> <br>
                        -држављанин Републике Србије са навршених 18 година,
                         <br>
                        -добио препоруку и позив за регистрацију од већ постојећег Регистрованог корисника.
                        <br><br>
                        Приликом регистрације корисник је дужан да у одговарајућа поља унесе исправне и веродостојне податке. Приликом креирања лозинке, корисник је дужан да употреби симболе који ће створити јаку лозинку. Корисник је дужан да лозинку чува у тајности.
                        <br><br>
                        Регистровани корисник може имати само један кориснички налог, који мора да буде под његовим правим личним именом. Корисник нема право да пренесе, уступи или стави на располагање било који део свог налога другом лицу.
                        <br><br>
                        Корисник је дужан да редовно ажурира регистроване информације, те да у случају промена које се односе на услове из овог дела, а које доводе до престанка њиховог испуњавања, у најкраћем могућем року обавести Удружење о томе или покрене процес брисања налога.
                        <br><br>
                        Б) Посетилац
                        <br><br>
                        Свако лице заинтересовано да се упозна и информише о садржајима доступним на Платформи (чему она служи, које теме и радионице обрађује, новости из света науке, информације о истраживачима и др.) може посетити Платформу.
                        <br><br>
                        За посећивање Платформе није потребна регистрација.
                        <br><br>
                        В) Коришћење Платформе
                        <br><br>
                        Корисник може користити Платформу на начин и у сврхе које су предвиђене овим Правилима, а у складу са природом и наменом одређеног садржаја коме приступа, односно користи. Примера ради, Регистровани корисник може изменити садржај своје личне странице тј. налога на сајту, претражити све истраживаче у бази и позивати нове чланове, док Посетилац може претраживати само истраживаче чији је садржај профила јавно доступан као и друге доступне интернет странице Платформе у сврху упознавања са њиховом садржином и информисања о новостима, радионицама и темама.
                        <br><br>
                        У току коришћења, Корисник је дужан да поступа савесно и одговорно уз одговарајући степен пажње, водећи рачуна да својим поступцима не омета или не утиче на нормално функционисање и рад Платформе и да не повреди права трећих лица, поштујући правила здраве и примерене (професионалне) комуникације приликом ступања у контакт са Регистрованим корисницима, све време уважавајући и поштујући легитимне интересе Организације Serbian Researchers.
                        <br><br>
                        Поред општег правила из параграфа 1. и 2. овог дела под В), Корисник је сагласан и:
                        <br><br>
                        -да неће правити лажне налоге, лажно представити било који део свог идентитета, направити налог на своје лично име али за потребе или у корист другог лица или користити налог другог Регистрованог корисника,
                        <br>
                        -да неће развијати, подржавати или користити софтвер, уређаје, компјутерску технологију или процесе помоћу којих би угрозио, умањио, нашкодио, заобишао, избегао, ограничио или утицао на сигурносне системе и безбедност Платформе и њене функционалности,
                        <br>
                        -да неће развијати, подржавати или користити софтвер, уређаје, компјутерску технологију или процесе помоћу којих би неовлашћено систематски прикупљао и копирао податке са Платформе,
                        <br>
                        -да неће копирати, користити (осим на начин и у сврхе предвиђене овим Правилима), открити или (економски) дистрибуирати било које податке добијене коришћењем Платформе, без сагласности Удружења или Регистрованог корисника уколико се ти подаци односе искључиво на тог корисника,
                        <br>
                        -да неће повредити права интелектуалне својине трећих лица укључујући, али не ограничавајући се на, ауторско право, жиг и патент,
                        <br>
                        -да неће повредити права интелектуалне својине Удружења на/у погледу Платформе, укључујући, али не ограничавајући се на, копирање или дистрибуцију текстова, чланака и других садржаја доступних на Платформи, копирање или дистрибуцију технологије уграђене у Платформу, осим уколико је она употребљена на основу open source лиценце,
                        <br>
                        -да ће поштовати све релевантне прописе укључујући, али не ограничавајући се на, оне којима се уређује заштита личних права, података о личности, права интелектуалне својине и др.
                        <br><br>
                        Имајући у виду да Платформа омогућава, на више начина, размену порука и дељење информација попут налога Регистрованих корисника, чланака, веза ка интернет страницама и др, информације и садржаји које Корисник објави или подели на Платформи могу бити доступни другим Корисницима. Корисник се може служити алаткама и функционалностима Платформе за подешавање врсте и обима информација и садржаја које Корисник жели да објави или подели тако да буду доступни другим Корисницима.
                        <br><br>
                        Корисници могу користити Платформу само за личне, професионалне и некомерцијалне потребе. Ова одредба се не примењује на однос између Корисника и њихових института и компанија који постоји и реализује се изван Платформе, без обзира да ли је тај однос инициран или настао путем Платформе.

                    </p>

                    <h4 class="title_s title_lightblue mt-4">
                        IV	ПРАВА И ОБАВЕЗЕ
                    </h4>

                    <p class="text_l mt-3">


                        Регистровани корисник је држалац свог налога. У том смислу, за све радње и активности током коришћења налога искључиво је одговоран Регистровани корисник, осим у случају да је пријавио злоупотребу.
                        <br><br>
                        Одговорност корисника из параграфа 1. ове главе је искључена у случају постојања злоупотребе или наступања околности које компромитују исправност налога (противправни сајбер напад, малфункција (енг. malfunction) услед пада система, вирус и др.), а на које корисник није утицао или није могао да утиче (у погледу настанка, ефекта или последице).
                        <br><br>
                        Сваку злоупотребу и наступање околности које компромитују исправност налога Регистровани корисник је дужан да одмах, односно у најкраћем могућем року пријави Удружењу на имејл адресу: <span class="title_blue">info@serbianresearchers.com</span>.
                        <br><br>
                        У односу између Корисника и Удружења, Корисник је власник, односно носилац одговарајућих овлашћења на/у погледу садржаја и података које доставља Удружењу приликом регистрације, интеракције или другог коришћења Платформе.
                        <br><br>
                        У погледу садржаја и података из параграфа 4. ове главе, Корисник даје Удружењу неискључиво овлашћење (лиценцу) које је територијално неограничено (тзв. светска лиценца), преносиво, уступљиво (право на давање подлиценце), на радње коришћења, копирања, прилагођавања, дистрибуирања, објављивања и обрађивања, без обавезе на давање или прибављање додатних/накнадних сагласности, обавештавање и/или права на накнаду Кориснику или трећем лицу. Ове радње ће се предузимати само за намене и сврхе предвиђене овим Правилима, за потребе рада и функционисања Платформе и њених садржаја и остваривање циљева Организације Serbian Researchers.
                        <br><br>
                        Удружење нема обавезу да похрани или приказује на Платформи било коју информацију или садржај од Корисника и има право да их уклони са или без обавештавања Корисника о томе (из разлога обавезности на основу закона или другог општег прописа/акта, одлуке суда или управног органа, због кршења одредаба ових Правила, заштите права и легитимних интереса Организације Serbian Researchers, безбедности, правилног рада и функционалности Платформе и других оправданих разлога).
                        <br><br>
                        Корисник се обавезује да неће доставити, објавити и поделити садржај или информацију који крше закон и друге опште акте или повређују права трећих лица (укључујући, али не ограничавајући се на право интелектуалне својине, заштите пословне тајне и поверљивих информација, заштите података о личности).
                        <br><br>
                        Удружење може променити, обуставити или укинути било који део или функционалност Платформе. Удружење не гарантује да ће чувати или приказивати било које информације и садржај који је Корисник доставио, објавио или поделио. Платформа не представља сервис складиштења података, већ ову услугу Удружење наручује од трећих лица (тзв. хостинг провајдера). Корисник је сагласан да Удружење нема обавезу складиштења, чувања или давања копије било ког садржаја или информације које је Корисник или неко треће лице доставило, објавило или поделило, осим у мери у којој то представља обавезу на основу важећег закона (примера ради, Закона о заштити података о личности).
                        <br><br>
                        Коришћењем Платформе, Корисник може наићи или приступити садржају или информацији које могу бити нетачни, непотпуни, обмањујући, противправни, увредљиви или на било који други начин штетни. Удружење начелно не проверава садржај који пружају Корисници. Корисник је сагласан да Удружење није одговорно за садржај или информације других Корисника и да ће их користи на сопствену одговорност. Корисник разуме да Удружење не може увек спречити злоупотребу коришћења Платформе и сагласан је да оно није одговорно за било какву такву злоупотребу. Наравно, Удружење ће предузети све разумне кораке и напоре у складу са својом организацијом и расположивим средствима, у сузбијању злоупотреба и других противправних радњи на/у вези са Платформом.
                        <br><br>
                        Регистровани корисник има право да у било ком тренутку покрене процес брисања свог налога. Удружење ће уклонити налог у најкраћем могућем року, а узимајући у обзир време потребно за брисање резервних података (backup) рок може бити до 30 дана.
                        <br><br>
                        Корисник има и друга права и обавезе предвиђених осталим одредбама ових Правила.
                    </p>

                    <h4 class="title_s title_lightblue mt-4">
                        V	ОГРАНИЧЕЊЕ ОДГОВОРНОСТИ
                    </h4>

                    <p class="text_l mt-3">



                        Удружење не гарантује непрекидност коришћења Платформе, нити је на било који начин одговорно за евентуалну привремену недоступност било којег њеног дела, делимично или потпуно нефункционисање или погрешно функционисање истих, као ни за последице које би могле наступити њеним коришћењем.
                        <br><br>
                        Из разлога више силе или техничких проблема, могуће је да Платформа није доступна свима или неком делу Корисника у току одређених временских периода. У таквим случајевима Удружење не одговара за последице које могу настати услед кашњења и/или погрешне обраде електронских података.
                        <br><br>
                        Удружење не одговара за било коју штету или повреде које би могле настати из скривених недостатака, грешака, прекида, брисања, квара, кашњења у раду, прекида у комуникацијама, крађе, уништења, неовлашћеног приступа подацима или злоупотребе података од стране Корисника и трећих лица и понашања супротног овим Правилима.
                        <br><br>
                        Удружење задржава право да у било ком тренутку услед повреде правила предвиђених овим Правилима, повреде права других Корисника, повреде важећих закона и других општих прописа/аката или из других оправданих разлога, одбије објављивање или приказивање одређеног садржаја или информације и уклони исте, и у том случају не одговара за евентуалну штету насталу овим чињењем.
                        <br><br>
                        Удружење не гарантује за тачност, поузданост и ажурност података које Корисник уноси приликом регистрације на Платформи, а сам Корисник је, прихватањем ових Правила, сагласан да је дужан да приликом регистрације унесе тачне податке, те да Удружење није ни на који начин одговорно за било коју штету која је уносом нетачних података наступила за другог Корисника или било које треће лице.
                        <br><br>
                        Удружење није одговорно за било који садржај или информацију које објављују или деле сами Корисници, будући да не иницира њихов пренос, не врши њихов одабир који се преноси, не изузима или мења њихову суштину, нити бира њиховог примаоца.
                        <br><br>
                        Корисник је упознат и, прихватањем ових Правила је, сагласан да Удружење не може бити одговорно за радње и понашања других Корисника или трећих лица, као и да ризик од могуће штете у целости сноси Корисник. Коришћење Платформе је на искључиву одговорност Корисника.
                        <br><br>
                        Удружење задржава право измене, укидања (привремено или трајно) било ког дела Платформе, без претходног или накнадног одобрења или обавештења Корисника, водећи се добрим обичајима, а нарочито ради очувања интегритета и редовног одржавања Платформе и безбедости података, у којим случајевима неће одговарати за евентулану штету насталу овим чињењем.
                        <br><br>
                        Важећа су и друга ограничења предвиђена осталим одредбама ових Правила.

                    </p>

                    <h4 class="title_s title_lightblue mt-4">
                        VI	ИНТЕЛЕКТУАЛНА СВОЈИНА
                    </h4>

                    <p class="text_l mt-3">


                        Удружење је носилац свих права интелектуалне својине и других овлашћења на/у погледу Платформе.
                        <br><br>
                        Корисник који достави, објави или подели одређени садржај или информацију сматра се да има сва права и друга овлашћења на предузимање таквих радњи у погледу тих садржаја и информација.

                    </p>

                    <h4 class="title_s title_lightblue mt-4">
                        VII	КОРИШЋЕЊЕ И ЗАШТИТА ПОДАТАКА О ЛИЧНОСТИ
                    </h4>

                    <p class="text_l mt-3">


                        Прикупљање, обрада, коришћење и заштита података о личности од стране Удружења се обавља у складу са важећим прописима Р. Србије и <a href="/privacy-policy" class="title_blue">Политиком приватности</a>  која је доступна на увид свим Корисницима. Прихватањем ових Правила, Корисник потврђује да је упознат и у целости сагласан са <a href="/privacy-policy" class="title_blue">Политиком приватности</a>.
                        <br><br>
                        Лични подаци се прикупљају од Корисника и обрађују, претежно, у сврху регистрације и вођења аналитике (на основу услуге доступне путем <a href="https://analytics.google.com/analytics/web/provision/#/provision" class="title_blue">Google Analytics</a>). Све друге сврхе прикупљања и обраде података наведене су у <a href="/privacy-policy" class="title_blue">Политиком приватности</a>. Основ и начин обраде, чување података, као и трајање чувања података, такође су наведени у <a href="/privacy-policy" class="title_blue">Политиком приватности</a>.
                        <br><br>
                        Удружење може једну или више радњи обраде поверити или дати приступ трећим лицима са којима има уговорни или радни однос, било директно било преко овлашћеног посредника (примера ради, „хостинг провајдеру“ на чијем серверу ће бити похрањени подаци, IT агенцији за одржавање и унапређење Платформе, законском заступнику Удружења и другим лицима која раде или су ангажована за потребе Платформе и др.). Корисници треба да буду свесни да ће одређени подаци о личности, укључујући податке достављене приликом регистрације у зависности од врсте и обима које је Корисник одабрао, бити објављени и приказани на Платформи, доступни другим Корисницима, односно бити јавно доступни на интернету.
                        <br><br>
                        Давање личних података је добровољно и заснива се на слободном приступу Платформи или регистрацијом. Међутим, Корисник који приликом регистрације не жели да унесе своје личне податке, прихвата да ће му у том случају бити онемогућено да се региструје на Платформи, као и да одређене делове и функционалности Платформе неће моћи да користи. Такав Корисник и даље може користити Платформу у сврхе претраживања садржаја, упознавања са Интернет презентацијом и у информативне сврхе. Додатно, одређени подаци се прикупљају и обрађују по аутоматизму, самим приступом Платформи (попут, време и државе из које се приступа, тип уређаја са ког се приступа и др.).
                        <br><br>
                        Удружење не одговара за тачност података које су коришћењем Платформе унела друга лица, као ни за тачност резултата обраде таквих података.

                    </p>

                    <h4 class="title_s title_lightblue mt-4">
                        VIII	КОНТАКТ ПОДАЦИ И КОМУНИКАЦИЈА
                    </h4>

                    <p class="text_l mt-3">


                        Удружење које администрира и управља овом Платформом је правно лице основано у складу са Законом о удружењима и регистровано пред Агенцијом за привредне регистре Републике Србије.
                        <br><br>
                        Контакт подаци су:
                        <br><br>
                        Назив Удружења: СРПСКИ ИСТРАЖИВАЧИ,<br>
                        Адреса: Господар Јованова 43, 11000 Београд, Србија,<br>
                        МБ: 28321406,<br>
                        ПИБ: 112436772,<br>
                        Имејл: <span class="title_blue">info@serbianresearchers.com</span>.<br>
                        <br><br>
                        Област остваривања циљева и циљеви Удружења:
                        Повезивање и едукација особа које се баве истраживачким радом из области науке и уметности.
                        <br><br>
                        У сврху успостављања и одржавања доброг односа и сарадње између Корисника и Удружења поводом коришћења Платформе, Корисник је сагласан да може бити контактиран од стране Удружења путем електронске поште (имејла), контакт телефона, „обичне“ поште или на други пригодан начин на основу података које је Корисник доставио Удружењу. Корисник је сагласан и да може примати обавештења о садржајима и новитетима на Платформи, уколико се пријавио на опцију новости (енг. newsletter).

                    </p>

                    <h4 class="title_s title_lightblue mt-4">
                        IX	ЗАВРШНЕ ОДРЕДБЕ
                    </h4>

                    <p class="text_l mt-3">


                        Ова Правила су подложна променама. У случају значајних измена (нпр. укидање или умањење права, увођење додатних обавеза Корисника и др.), Удружење ће све регистроване Кориснике обавестити о изменама на активан начин, на пример путем имејла или јасно уочљивих обавештења (push или pop up нотификација). Измењена Политика приватности ступа на снагу у року од осам дана од дана објављивања на Платформи, те ја за ово време Корисник дужан да се упозна са документом. Удружење може да одреди и дужи рок ступања на снагу. Уколико измене нису, пак, прихватљиве за Корисника, дужан је да покрене процес брисања свог налога. Удружење задржава право да од Корисника затражи поновно прихватање ових Правила за коришћење Платформе, те да ускрати приступ Кориснику који не прихвати промењена Правила.
                        <br><br>
                        Корисник је дужан да поштује и да се придржава ових Правила. Било која повреда одредаба ових Правила од стране Корисника, у зависности од тежине повреде, може за последицу имати уклањање спорних садржаја Корисника, ограничење приступа садржајима на Платформи и привремено или трајно укидање налога. Свакако, уколико је Платформи, Организацији Serbian Researchers, односно Удружењу таквим поступцима причињена одређена штета Удружење има право да против тог Корисника-штетника покрене одговарајуће управне и судске поступке ради заштите својих права и накнаде штете.
                        <br><br>
                        Уколико поједине одредбе ових Правила постану у целости или делимично ништаве или неважеће, то неће утицати на правну ваљаност преосталих одредби Правила.
                        <br><br>
                        У случају правних спорова у вези са овим Правилима примењује се законодавство Републике Србије.
                        <br><br>
                        Уговорне стране ће настојати да све спорове или несугласице који настану из или у вези са овим Правилима решавају мирним путем. Уколико то није могуће, спорови ће се решавати пред месно и стварно надлежним судом у Р. Србији.
                        <br><br>
                        У Београду, 20.04.2021. године

                    </p>

                    <h4 class="title_s title_lightblue mt-4">
                        ПИТАЊА, КОМЕНТАРИ, СУГЕСТИЈЕ, ПРИМЕДБЕ:
                    </h4>

                    <p class="text_l mt-3">


                        У сваком тренутку, стојимо на располагању за Ваша питања, коментаре, сугестије, запажања, идеје, предлоге, примедбе, приговоре и жалбе, те нас слободно контактирајте у вези са овим Правилима, <a class="title_blue" href="/privacy-policy">Политиком приватности</a> и Платформом, путем Интернет презентације <a class="title_blue" href="https://serbianresearchers.com">www.serbianresearchers.com </a> или путем имејла: <span class="title_blue">info@serbianresearchers</span>.com.

                    </p>

                </div>

                <div class="col-12 aos-init" data-aos="fade-up" data-aos-duration="1000">
                    <h3 class="title_xl title_lightblue">
                        PRAVILA I USLOVI KORIŠĆENJA
                    </h3>
                  <h4 class="title_s mt-5 title_lightblue">
                    UKRATKO
                  </h4>

                  <ul class="text_l mt-3">
                    <li>
                      Svrha Platforme je umrežavanje srpskih istraživača iz Srbije i dijaspore, razmena znanja i iskustva, saradnja akademskih institucija, istraživačkih instituta i kompanija, plasiranje vesti i noviteta iz oblasti nauke i tehnološkog razvoja.
                    </li>
                    <li>
                    Platforma je namenjena svim zainteresovanim licima, a u zavisnosti od kategorije korisnika, na njoj se mogu registrovati istraživači, dok ostali korisnici se mogu upoznati i informisati o različitim sadržajima.
                    </li>
                    <li>
                    Pristup i registracija na Platformi se zasnivaju na dobrovoljnoj bazi.
                    </li>
                    <li>
                    Da bi se istraživač registrovao mora da bude povezan sa odgovarajućom institucijom ili kompanijom, da ima doprinos respektivnoj naučnoj oblasti, navršenih 18 godina i poziv od već registrovanog istraživača.
                    </li>
                    <li>
                    Registrovani istraživač je dužan da se stara o svom profilu i da redovno ažurira podatke o sebi.
                    </li>
                    <li>
                    Prilikom korišćenja Platforme, registrovani istraživač i drugi korisnici su dužni da postupaju savesno, razumno i u skladu sa dobrim običajima, ne ugrožavajući/povređujući prava bilo kog lica. Korisnik je odgovoran za sadržaj i informacije koje objavljuje ili deli na/putem Platforme.
                    </li>
                    <li>
                    Informacije koje je objavio korisnik su vidljive ostalima, koji ih mogu kopirati i koristiti (osim onih za koje je u postavkama određeno drugačije).
                    </li>
                    <li>
                    Lični podaci korisnika se obrađuju u svrhe u koje su i dati (registracija istraživača, prijava na novosti (eng. newsletter), interakcija sa korisnikom i dr.), i to u vremenskom periodu potrebnom da se ostvari konkretna svrha (dok traje registracija i 30 dana nakon toga, dok traje prijava na novosti, za vreme u skladu sa zakonskim propisima i dr.).
                    </li>
                    <li>
                    Korisnik može biti kontaktiran od strane Udruženja u vezi sa korišćenjem i aktivnostima na Platformi.
                    </li>
                    <li>
                    Ova Pravila se mogu nekad promeniti, te korisnik ukoliko nije saglasan sa izmenjenim pravilima može u svakom trenutku da izbriše nalog, odnosno da više ne posećuje Platformu.
                    </li>
                  </ul>
                  <hr class="mt-4">
                    <p class="text_l mt-5">

                        Poštovani,
                        <br><br>
                        Pred vama se nalaze Pravila i uslovi korišćenja kojima se reguliše korišćenje internet prezentacije i platforme Serbian Researchers (u daljem tekstu: <b>Internet prezentacija, Internet platforma</b> ili <b>Platforma</b>, postojeća na internet adresi: <a href="https://serbianresearchers.com/" class="title_blue">https://www.serbianresearchers.com/</a> ). Molimo vas da pažljivo pročitate ova Pravila i uslove korišćenja (u daljem tekstu: <b>Pravila</b>) imajući u vidu da se ona primenjuju na vas, odnosno na vaše korišćenje Platforme.
                        <br><br>
                        Pored Pravila, i zajedno sa njima, primenjuje se i <a href="/privacy-policy" class="title_blue">Politika privatnosti</a> kojom se uređuje  prikupljanje, obrada, korišćenje i zaštita ličnih podataka, u skladu sa Zakonom o zaštiti podataka o ličnosti ("Sl. glasnik RS", br. 87/2018).
                        <br><br>
                        Svaka poseta Platformi ili registracijom znači da ste upoznati, odnosno pročitali Pravila i <a href="/privacy-policy" class="title_blue">Politiku privatnosti</a>, razumeli predmet njihovog uređivanja i da se slažete sa njima u celosti. Ukoliko, pak, oni za vas nisu prihvatljivi, molimo vas da ne koristite Platformu.

                    </p>

                    <h4 class="title_s title_lightblue mt-4">
                        I	OSNOVNE ODREDBE
                    </h4>

                    <p class="text_l mt-3">
                        Organizacija Serbian Researchers ima za cilj umrežavanje srpskih istraživača širom sveta putem kreiranja platforme za deljenje znanja i iskustva između pojedinaca koji se bave naučno-istraživačkim radom u Srbiji i inostranstvu. Platforma omogućava povezivanje istraživača iz različitih respektivnih sfera i oblasti i doprinosi razmeni ideja i saradnji između univerziteta i kompanija. Stoga, ona je, pre svega, namenjena fizičkim licima koja svoje profesionalne afinitete realizuju radom ili saradnjom u akademskim institucijama, naučno-istraživačkim institutima i razvojno-istraživačkim centrima unutar kompanija. Takođe, Platformi može pristupiti i svako zainteresovano lice u svrhu informisanja o temama, radionicama i drugim sadržajima dostupnim na njoj.
                        <br><br>
                        Internet prezentacijom i platformom administrira i upravlja Udruženje SRPSKI ISTRAŽIVAČI, koje poseduje, odnosno koje je nosilac odgovarajućih prava i ovlašćenja na/u pogledu Platforme.
                        <br><br>
                        Pristup Platformi ili registracija na njoj zasniva se na dobrovoljnoj osnovi, te ukoliko lice preduzme navedene radnje potvrđuje da je upoznato sa Pravilima i <a href="/privacy-policy" class="title_blue">Politikom privatnosti</a>, razumelo predmet njihovog uređivanja i saglasno sa njihovom primenom.


                    </p>

                    <h4 class="title_s title_lightblue mt-4">
                        II	UGOVORNE STRANE
                    </h4>

                    <p class="text_l mt-3">
                        Ugovorna strana koja administrira i upravlja Platformom je Udruženje SRPSKI ISTRAŽIVAČI, Gospodar Jovanova 43, 11000 Beograd, Srbija, MB: 28321406, PIB: 112436772 (u daljem tekstu: <b>Udruženje</b> ili <b>Serbian Researchers</b>).
                        <br><br>
                        Ugovorna strana koja pristupa Platformi u svrhu upoznavanja sa njom i informisanja o temama, radionicama, te uopšteno o sadržaju dostupnim na njoj je Posetilac.
                        <br><br>
                        Ugovorna strana koja izvrši registraciju, te koristi Platformu u svrhe predviđene ovim Pravilima (povezivanje sa drugim istraživačima, deljenje znanja i iskustva i sl.) je Registrovani korisnik.
                        <br><br>
                        Članstvo u Organizaciji ne podrazumeva nužno i članstvo u Udruženju, već se odnosi na registraciju istraživača za dobijanje statusa Registrovanog korisnika Platforme i pripadnost zajednici istraživača koju Organizacija okuplja na Platformi.
                        <br><br>
                        Zajednički imenilac za Posetioca i Registrovanog korisnika je Korisnik.

                    </p>

                    <h4 class="title_s title_lightblue mt-4">
                        III	NAČIN KORIŠĆENJA PLATFORME
                    </h4>

                    <p class="text_l mt-3">


                        Platformu mogu koristiti Korisnici (Registrovani korisnici i Posetioci) u svrhe i namene navedene u delu I – Osnovne odredbe, odnosno u skladu sa drugim odgovarajućim odredbama iz ovih Pravila, a shodno prirodi sadržaja koji su dostupni ovim kategorijama Korisnika.
                        <br><br>
                        A) Registrovani korisnik
                        <br><br>
                        Za registraciju je, prethodno, potrebno da korisnik kumulativno ispuni dva uslova:
                        <br><br>
                        -povezanost sa odgovarajućom institucijom, odnosno kompanijom,
                        <br>
                        -doprinos respektivnoj naučnoj oblasti.
                        <br><br>
                        Povezanost sa odgovarajućom institucijom, odnosno kompanijom korisnik ostvaruje radom/zaposlenjem u (alternativno):
                        <br><br>
                        -akreditovanoj akademskoj instituciji,
                        <br>
                        -naučno-istraživačkim centrima i institutima,
                        <br>
                        -razvojno-istraživačkim centrima unutar kompanija.
                        <br><br>
                        Doprinos respektivnoj naučnoj oblasti korisnik ostvaruje (alternativno):
                        <br><br>
                        -objavljivanjem najmanje jednog naučnog rada, bilo gde u svetu, nezavisno da li je korisnik jedini autor ili koautor tog rada,
                        <br>
                        -autorstvom pronalaska zaštićenog patentom (izvršena registracija i priznata patentna zaštita), nezavisno da li je korisnik prodao ili licencira patent drugom licu,
                         <br>
                        -pohađanjem doktorskih studija na akreditovanoj akademskoj instituciji (u Srbiji ili u inostranstvu).
                        <br><br>
                        Pored ispunjenja kumulativno postavljenih uslova potrebno je da je korisnik:
                         <br> <br>
                        -državljanin Republike Srbije sa navršenih 18 godina,
                         <br>
                        -dobio preporuku i poziv za registraciju od već postojećeg Registrovanog korisnika.
                        <br><br>
                        Prilikom registracije korisnik je dužan da u odgovarajuća polja unese ispravne i verodostojne podatke. Prilikom kreiranja lozinke, korisnik je dužan da upotrebi simbole koji će stvoriti jaku lozinku. Korisnik je dužan da lozinku čuva u tajnosti.
                        <br><br>
                        Registrovani korisnik može imati samo jedan korisnički nalog, koji mora da bude pod njegovim pravim ličnim imenom. Korisnik nema pravo da prenese, ustupi ili stavi na raspolaganje bilo koji deo svog naloga drugom licu.
                        <br><br>
                        Korisnik je dužan da redovno ažurira registrovane informacije, te da u slučaju promena koje se odnose na uslove iz ovog dela, a koje dovode do prestanka njihovog ispunjavanja, u najkraćem mogućem roku obavesti Udruženje o tome ili pokrene proces brisanja naloga.
                        <br><br>
                        B) Posetilac
                        <br><br>
                        Svako lice zainteresovano da se upozna i informiše o sadržajima dostupnim na Platformi (čemu ona služi, koje teme i radionice obrađuje, novosti iz sveta nauke, informacije o istraživačima i dr.) može posetiti Platformu.
                        <br><br>
                        Za posećivanje Platforme nije potrebna registracija.
                        <br><br>
                        C) Korišćenje Platforme
                        <br><br>
                        Korisnik može koristiti Platformu na način i u svrhe koje su predviđene ovim Pravilima, a u skladu sa prirodom i
                      namenom određenog sadržaja kome pristupa, odnosno koristi.
                      Primera radi, Registrovani korisnik može pretražiti sve istraživače u bazi, pozivati nove članove, izmeniti sadržaj svoje lične stranice tj. naloga na sajtu, dok Posetilac moze… pretraživati samo istraživače čiji je sadržaj profila javno dostupan kao i druge dostupne internet stranice Platforme u svrhu upoznavanja sa njihovom sadržinom i informisanja o novostima, radionicama i temama.
                        <br><br>
                        U toku korišćenja, Korisnik je dužan da postupa savesno i odgovorno uz odgovarajući stepen pažnje, vodeći računa da svojim postupcima ne ometa ili ne utiče na normalno funkcionisanje i rad Platforme i da ne povredi prava trećih lica, poštujući pravila zdrave i primerene (profesionalne) komunikacije prilikom stupanja u kontakt sa Registrovanim korisnicima, sve vreme uvažavajući i poštujući legitimne interese Organizacije Serbian Researchers.
                        <br><br>
                        Pored opšteg pravila iz paragrafa 1. i 2. ovog dela pod V), Korisnik je saglasan i:
                        <br><br>
                        -da neće praviti lažne naloge, lažno predstaviti bilo koji deo svog identiteta, napraviti nalog na svoje lično ime ali za potrebe ili u korist drugog lica ili koristiti nalog drugog Registrovanog korisnika,
                        <br>
                        -da neće razvijati, podržavati ili koristiti softver, uređaje, kompjutersku tehnologiju ili procese pomoću kojih bi ugrozio, umanjio, naškodio, zaobišao, izbegao, ograničio ili uticao na sigurnosne sisteme i bezbednost Platforme i njene funkcionalnosti,
                        <br>
                        -da neće razvijati, podržavati ili koristiti softver, uređaje, kompjutersku tehnologiju ili procese pomoću kojih bi neovlašćeno sistematski prikupljao i kopirao podatke sa Platforme,
                        <br>
                        -da neće kopirati, koristiti (osim na način i u svrhe predviđene ovim Pravilima), otkriti ili (ekonomski) distribuirati bilo koje podatke dobijene korišćenjem Platforme, bez saglasnosti Udruženja ili Registrovanog korisnika ukoliko se ti podaci odnose isključivo na tog korisnika,
                        <br>
                        -da neće povrediti prava intelektualne svojine trećih lica uključujući, ali ne ograničavajući se na, autorsko pravo, žig i patent,
                        <br>
                        -da neće povrediti prava intelektualne svojine Udruženja na/u pogledu Platforme, uključujući, ali ne ograničavajući se na, kopiranje ili distribuciju tekstova, članaka i drugih sadržaja dostupnih na Platformi, kopiranje ili distribuciju tehnologije ugrađene u Platformu, osim ukoliko je ona upotrebljena na osnovu open source licence,
                        <br>
                        -da će poštovati sve relevantne propise uključujući, ali ne ograničavajući se na, one kojima se uređuje zaštita ličnih prava, podataka o ličnosti, prava intelektualne svojine i dr.
                        <br><br>
                        Imajući u vidu da Platforma omogućava, na više načina, razmenu poruka i deljenje informacija poput naloga Registrovanih korisnika, članaka, veza ka internet stranicama i dr, informacije i sadržaji koje Korisnik objavi ili podeli na Platformi mogu biti dostupni drugim Korisnicima. Korisnik se može služiti alatkama i funkcionalnostima Platforme za podešavanje vrste i obima informacija i sadržaja koje Korisnik želi da objavi ili podeli tako da budu dostupni drugim Korisnicima.
                        <br><br>
                        Korisnici mogu koristiti Platformu samo za lične, profesionalne i nekomercijalne potrebe. Ova odredba se ne primenjuje na odnos između Korisnika i njihovih instituta i kompanija koji postoji i realizuje se izvan Platforme, bez obzira da li je taj odnos iniciran ili nastao putem Platforme.

                    </p>

                    <h4 class="title_s title_lightblue mt-4">
                        IV	PRAVA I OBAVEZE
                    </h4>

                    <p class="text_l mt-3">


                        Registrovani korisnik je držalac svog naloga. U tom smislu, za sve radnje i aktivnosti tokom korišćenja naloga isključivo je odgovoran Registrovani korisnik, osim u slučaju da je prijavio zloupotrebu.
                        <br><br>
                        Odgovornost korisnika iz paragrafa 1. ove glave je isključena u slučaju postojanja zloupotrebe ili nastupanja okolnosti koje kompromituju ispravnost naloga (protivpravni sajber napad, malfunkcija (eng. malfunction) usled pada sistema, virus i dr.), a na koje korisnik nije uticao ili nije mogao da utiče (u pogledu nastanka, efekta ili posledice).
                        <br><br>
                        Svaku zloupotrebu i nastupanje okolnosti koje kompromituju ispravnost naloga Registrovani korisnik je dužan da odmah, odnosno u najkraćem mogućem roku prijavi Udruženju putem <router-link to="/contact" target="_blank">Kontakt forme</router-link>.
                        <br><br>
                        U odnosu između Korisnika i Udruženja, Korisnik je vlasnik, odnosno nosilac odgovarajućih ovlašćenja na/u pogledu sadržaja i podataka koje dostavlja Udruženju prilikom registracije, interakcije ili drugog korišćenja Platforme.
                        <br><br>
                        U pogledu sadržaja i podataka iz paragrafa 4. ove glave, Korisnik daje Udruženju neisključivo ovlašćenje (licencu) koje je teritorijalno neograničeno (tzv. svetska licenca), prenosivo, ustupljivo (pravo na davanje podlicence), na radnje korišćenja, kopiranja, prilagođavanja, distribuiranja, objavljivanja i obrađivanja, bez obaveze na davanje ili pribavljanje dodatnih/naknadnih saglasnosti, obaveštavanje i/ili prava na naknadu Korisniku ili trećem licu. Ove radnje će se preduzimati samo za namene i svrhe predviđene ovim Pravilima, za potrebe rada i funkcionisanja Platforme i njenih sadržaja i ostvarivanje ciljeva Organizacije Serbian Researchers.
                        <br><br>
                        Udruženje nema obavezu da pohrani ili prikazuje na Platformi bilo koju informaciju ili sadržaj od Korisnika i ima pravo da ih ukloni sa ili bez obaveštavanja Korisnika o tome (iz razloga obaveznosti na osnovu zakona ili drugog opšteg propisa/akta, odluke suda ili upravnog organa, zbog kršenja odredaba ovih Pravila, zaštite prava i legitimnih interesa Organizacije Serbian Researchers, bezbednosti, pravilnog rada i funkcionalnosti Platforme i drugih opravdanih razloga).
                        <br><br>
                        Korisnik se obavezuje da neće dostaviti, objaviti i podeliti sadržaj ili informaciju koji krše zakon i druge opšte akte ili povređuju prava trećih lica (uključujući, ali ne ograničavajući se na pravo intelektualne svojine, zaštite poslovne tajne i poverljivih informacija, zaštite podataka o ličnosti).
                        <br><br>
                        Udruženje može promeniti, obustaviti ili ukinuti bilo koji deo ili funkcionalnost Platforme. Udruženje ne garantuje da će čuvati ili prikazivati bilo koje informacije i sadržaj koji je Korisnik dostavio, objavio ili podelio. Platforma ne predstavlja servis skladištenja podataka, već ovu uslugu Udruženje naručuje od trećih lica (tzv. hosting provajdera). Korisnik je saglasan da Udruženje nema obavezu skladištenja, čuvanja ili davanja kopije bilo kog sadržaja ili informacije koje je Korisnik ili neko treće lice dostavilo, objavilo ili podelilo, osim u meri u kojoj to predstavlja obavezu na osnovu važećeg zakona (primera radi, Zakona o zaštiti podataka o ličnosti).
                        <br><br>
                        Korišćenjem Platforme, Korisnik može naići ili pristupiti sadržaju ili informaciji koje mogu biti netačni, nepotpuni, obmanjujući, protivpravni, uvredljivi ili na bilo koji drugi način štetni. Udruženje načelno ne proverava sadržaj koji pružaju Korisnici. Korisnik je saglasan da Udruženje nije odgovorno za sadržaj ili informacije drugih Korisnika i da će ih koristi na sopstvenu odgovornost. Korisnik razume da Udruženje ne može uvek sprečiti zloupotrebu korišćenja Platforme i saglasan je da ono nije odgovorno za bilo kakvu takvu zloupotrebu. Naravno, Udruženje će preduzeti sve razumne korake i napore u skladu sa svojom organizacijom i raspoloživim sredstvima, u suzbijanju zloupotreba i drugih protivpravnih radnji na/u vezi sa Platformom.
                        <br><br>
                        Registrovani korisnik ima pravo da u bilo kom trenutku pokrene proces brisanja svog naloga. Udruženje će ukloniti nalog u najkraćem mogućem roku, a uzimajući u obzir vreme potrebno za brisanje rezervnih podataka (backup) rok može biti do 30 dana.
                        <br><br>
                        Korisnik ima i druga prava i obaveze predviđenih ostalim odredbama ovih Pravila.
                    </p>

                    <h4 class="title_s title_lightblue mt-4">
                        V	OGRANIČENJE ODGOVORNOSTI
                    </h4>

                    <p class="text_l mt-3">



                        Udruženje ne garantuje neprekidnost korišćenja Platforme, niti je na bilo koji način odgovorno za eventualnu privremenu nedostupnost bilo kojeg njenog dela, delimično ili potpuno nefunkcionisanje ili pogrešno funkcionisanje istih, kao ni za posledice koje bi mogle nastupiti njenim korišćenjem.
                        <br><br>
                        Iz razloga više sile ili tehničkih problema, moguće je da Platforma nije dostupna svima ili nekom delu Korisnika u toku određenih vremenskih perioda. U takvim slučajevima Udruženje ne odgovara za posledice koje mogu nastati usled kašnjenja i/ili pogrešne obrade elektronskih podataka.
                        <br><br>
                        Udruženje ne odgovara za bilo koju štetu ili povrede koje bi mogle nastati iz skrivenih nedostataka, grešaka, prekida, brisanja, kvara, kašnjenja u radu, prekida u komunikacijama, krađe, uništenja, neovlašćenog pristupa podacima ili zloupotrebe podataka od strane Korisnika i trećih lica i ponašanja suprotnog ovim Pravilima.
                        <br><br>
                        Udruženje zadržava pravo da u bilo kom trenutku usled povrede pravila predviđenih ovim Pravilima, povrede prava drugih Korisnika, povrede važećih zakona i drugih opštih propisa/akata ili iz drugih opravdanih razloga, odbije objavljivanje ili prikazivanje određenog sadržaja ili informacije i ukloni iste, i u tom slučaju ne odgovara za eventualnu štetu nastalu ovim činjenjem.
                        <br><br>
                        Udruženje ne garantuje za tačnost, pouzdanost i ažurnost podataka koje Korisnik unosi prilikom registracije na Platformi, a sam Korisnik je, prihvatanjem ovih Pravila, saglasan da je dužan da prilikom registracije unese tačne podatke, te da Udruženje nije ni na koji način odgovorno za bilo koju štetu koja je unosom netačnih podataka nastupila za drugog Korisnika ili bilo koje treće lice.
                        <br><br>
                        Udruženje nije odgovorno za bilo koji sadržaj ili informaciju koje objavljuju ili dele sami Korisnici, budući da ne inicira njihov prenos, ne vrši njihov odabir koji se prenosi, ne izuzima ili menja njihovu suštinu, niti bira njihovog primaoca.
                        <br><br>
                        Korisnik je upoznat i, prihvatanjem ovih Pravila je, saglasan da Udruženje ne može biti odgovorno za radnje i ponašanja drugih Korisnika ili trećih lica, kao i da rizik od moguće štete u celosti snosi Korisnik. Korišćenje Platforme je na isključivu odgovornost Korisnika.
                        <br><br>
                        Udruženje zadržava pravo izmene, ukidanja (privremeno ili trajno) bilo kog dela Platforme, bez prethodnog ili naknadnog odobrenja ili obaveštenja Korisnika, vodeći se dobrim običajima, a naročito radi očuvanja integriteta i redovnog održavanja Platforme i bezbedosti podataka, u kojim slučajevima neće odgovarati za eventulanu štetu nastalu ovim činjenjem.
                        <br><br>
                        Važeća su i druga ograničenja predviđena ostalim odredbama ovih Pravila.

                    </p>

                    <h4 class="title_s title_lightblue mt-4">
                        VI	INTELEKTUALNA SVOJINA
                    </h4>

                    <p class="text_l mt-3">


                        Udruženje je nosilac svih prava intelektualne svojine i drugih ovlašćenja na/u pogledu Platforme.
                        <br><br>
                        Korisnik koji dostavi, objavi ili podeli određeni sadržaj ili informaciju smatra se da ima sva prava i druga ovlašćenja na preduzimanje takvih radnji u pogledu tih sadržaja i informacija.

                    </p>

                    <h4 class="title_s title_lightblue mt-4">
                        VII	KORIŠĆENJE I ZAŠTITA PODATAKA O LIČNOSTI
                    </h4>

                    <p class="text_l mt-3">


                        Prikupljanje, obrada, korišćenje i zaštita podataka o ličnosti od strane Udruženja se obavlja u skladu sa važećim propisima R. Srbije i <a href="/privacy-policy" class="title_blue">Politikom privatnosti</a>  koja je dostupna na uvid svim Korisnicima. Prihvatanjem ovih Pravila, Korisnik potvrđuje da je upoznat i u celosti saglasan sa <a href="/privacy-policy" class="title_blue">Politikom privatnosti</a>.
                        <br><br>
                        Lični podaci se prikupljaju od Korisnika i obrađuju, pretežno, u svrhu registracije i vođenja analitike (na osnovu usluge dostupne putem <a href="https://analytics.google.com/analytics/web/provision/#/provision" class="title_blue">Google Analytics</a>). Sve druge svrhe prikupljanja i obrade podataka navedene su u <a href="/privacy-policy" class="title_blue">Politikom privatnosti</a>. Osnov i način obrade, čuvanje podataka, kao i trajanje čuvanja podataka, takođe su navedeni u <a href="/privacy-policy" class="title_blue">Politikom privatnosti</a>.
                        <br><br>
                        Udruženje može jednu ili više radnji obrade poveriti ili dati pristup trećim licima sa kojima ima ugovorni ili radni odnos, bilo direktno bilo preko ovlašćenog posrednika (primera radi, „hosting provajderu“ na čijem serveru će biti pohranjeni podaci, IT agenciji za održavanje i unapređenje Platforme, zakonskom zastupniku Udruženja i drugim licima koja rade ili su angažovana za potrebe Platforme i dr.). Korisnici treba da budu svesni da će određeni podaci o ličnosti, uključujući podatke dostavljene prilikom registracije u zavisnosti od vrste i obima koje je Korisnik odabrao, biti objavljeni i prikazani na Platformi, dostupni drugim Korisnicima, odnosno biti javno dostupni na internetu.
                        <br><br>
                        Davanje ličnih podataka je dobrovoljno i zasniva se na slobodnom pristupu Platformi ili registracijom. Međutim, Korisnik koji prilikom registracije ne želi da unese svoje lične podatke, prihvata da će mu u tom slučaju biti onemogućeno da se registruje na Platformi, kao i da određene delove i funkcionalnosti Platforme neće moći da koristi. Takav Korisnik i dalje može koristiti Platformu u svrhe pretraživanja sadržaja, upoznavanja sa Internet prezentacijom i u informativne svrhe. Dodatno, određeni podaci se prikupljaju i obrađuju po automatizmu, samim pristupom Platformi (poput, vreme i države iz koje se pristupa, tip uređaja sa kog se pristupa i dr.).
                        <br><br>
                        Udruženje ne odgovara za tačnost podataka koje su korišćenjem Platforme unela druga lica, kao ni za tačnost rezultata obrade takvih podataka.

                    </p>

                    <h4 class="title_s title_lightblue mt-4">
                        VIII	KONTAKT PODACI I KOMUNIKACIJA
                    </h4>

                    <p class="text_l mt-3">


                        Udruženje koje administrira i upravlja ovom Platformom je pravno lice osnovano u skladu sa Zakonom o udruženjima i registrovano pred Agencijom za privredne registre Republike Srbije.
                        <br><br>
                        Kontakt podaci su:
                        <br><br>
                        Naziv Udruženja: SRPSKI ISTRAŽIVAČI,<br>
                        Adresa: Gospodar Jovanova 43, 11000 Beograd, Srbija,<br>
                        MB: 28321406,<br>
                        PIB: 112436772,<br>
                        <router-link to="/contact" target="_blank">Kontakt forma</router-link>.<br>
                        <br><br>
                        Oblast ostvarivanja ciljeva: Povezivanje i edukacija osoba koje se bave istraživačkim radom iz oblasti nauke i umetnosti.
                      <br><br>
                      Ciljevi Udruženja:
                      <br><br>
                      1. kreiranje sveobuhvatne baze istraživača;<br>
                      2. promocija nauke, umetnosti i istraživačkog rada;<br>
                      3. edukacija istraživača;<br>
                      4. uspostavljanje kontakta između privrednih subjekata koji zapošljavaju istraživače;<br>
                      5. organizacija konferencija, diskusija i okruglih stolova.
                        <br><br>
                        U svrhu uspostavljanja i održavanja dobrog odnosa i saradnje između Korisnika i Udruženja povodom korišćenja Platforme, Korisnik je saglasan da može biti kontaktiran od strane Udruženja putem elektronske pošte (imejla), kontakt telefona, „obične“ pošte ili na drugi prigodan način na osnovu podataka koje je Korisnik dostavio Udruženju. Korisnik je saglasan i da može primati obaveštenja o sadržajima i novitetima na Platformi, ukoliko se prijavio na opciju novosti (eng. newsletter).

                    </p>

                    <h4 class="title_s title_lightblue mt-4">
                        IX	ZAVRŠNE ODREDBE
                    </h4>

                    <p class="text_l mt-3">


                        Ova Pravila su podložna promenama. U slučaju značajnih izmena (npr. ukidanje ili umanjenje prava, uvođenje dodatnih obaveza Korisnika i dr.), Udruženje će sve registrovane Korisnike obavestiti o izmenama na aktivan način, na primer putem imejla ili jasno uočljivih obaveštenja (push ili pop up notifikacija). Izmenjena Politika privatnosti stupa na snagu u roku od osam dana od dana objavljivanja na Platformi, te ja za ovo vreme Korisnik dužan da se upozna sa dokumentom. Udruženje može da odredi i duži rok stupanja na snagu. Ukoliko izmene nisu, pak, prihvatljive za Korisnika, dužan je da pokrene proces brisanja svog naloga. Udruženje zadržava pravo da od Korisnika zatraži ponovno prihvatanje ovih Pravila za korišćenje Platforme, te da uskrati pristup Korisniku koji ne prihvati promenjena Pravila.
                        <br><br>
                        Korisnik je dužan da poštuje i da se pridržava ovih Pravila. Bilo koja povreda odredaba ovih Pravila od strane Korisnika, u zavisnosti od težine povrede, može za posledicu imati uklanjanje spornih sadržaja Korisnika, ograničenje pristupa sadržajima na Platformi i privremeno ili trajno ukidanje naloga. Svakako, ukoliko je Platformi, Organizaciji Serbian Researchers, odnosno Udruženju takvim postupcima pričinjena određena šteta Udruženje ima pravo da protiv tog Korisnika-štetnika pokrene odgovarajuće upravne i sudske postupke radi zaštite svojih prava i naknade štete.
                        <br><br>
                        Ukoliko pojedine odredbe ovih Pravila postanu u celosti ili delimično ništave ili nevažeće, to neće uticati na pravnu valjanost preostalih odredbi Pravila.
                        <br><br>
                        U slučaju pravnih sporova u vezi sa ovim Pravilima primenjuje se zakonodavstvo Republike Srbije.
                        <br><br>
                        Ugovorne strane će nastojati da sve sporove ili nesuglasice koji nastanu iz ili u vezi sa ovim Pravilima rešavaju mirnim putem. Ukoliko to nije moguće, sporovi će se rešavati pred mesno i stvarno nadležnim sudom u R. Srbiji.
                        <br><br>
                        U Beogradu, 20.04.2021. godine

                    </p>

                    <h4 class="title_s title_lightblue mt-4">
                        PITANJA, KOMENTARI, SUGESTIJE, PRIMEDBE:
                    </h4>

                    <p class="text_l mt-3">


                        U svakom trenutku, stojimo na raspolaganju za Vaša pitanja, komentare, sugestije, zapažanja, ideje, predloge, primedbe, prigovore i žalbe,
                      te nas slobodno kontaktirajte u vezi sa ovim Pravilima, <a class="title_blue" href="/privacy-policy">Politikom privatnosti</a>
                      i Platformom, putem Internet prezentacije <a class="title_blue" href="https://serbianresearchers.com">www.serbianresearchers.com</a>.

                    </p>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
    name: 'Hero'
};
</script>
